
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [

      // DASHBOARD
        {
          path: '/',
          name: 'Home',
          component: () => import('./views/pages/Home.vue'),
          meta: { 
            //auth: true 
          }
        },
        {
          path: '/jadwal',
          name: 'Jadwal',
          component: () => import('./views/pages/Jadwal.vue'),
          meta: { 
            auth: true 
          }
        },
      //

      // LAYANAN
        {
          path: '/layanan',
          name: 'Layanan',
          component: () => import('./views/layanan/Layanan.vue'),
          meta: { 
            //auth: true 
          }
        },
        {
          path: '/layanan-item',
          name: 'LayananItem',
          component: () => import('./views/layanan/LayananItem.vue'),
          meta: { 
            //auth: true 
          }
        },
        {
          path: '/layanan-data',
          name: 'LayananData',
          component: () => import('./views/layanan/LayananData.vue'),
          meta: { 
            auth: true 
          }
        },
        {
          path: '/layanan-jadwal',
          name: 'LayananJadwal',
          component: () => import('./views/layanan/LayananJadwal.vue'),
          meta: { 
            auth: true 
          }
        },
      //

      // Post
        {
          path: '/post/:kategori',
          name: 'post',
          component: () => import('./views/post/PostList.vue'),
          meta: { 
            //auth: true 
          }
        },
        {
          path: '/post-read',
          name: 'post-read',
          component: () => import('./views/post/PostRead.vue'),
          meta: { 
            //auth: true 
          }
        },
      //

      // Permohonan
        {
          path: '/permohonan/:filter_type/:filter',
          name: 'Permohonan',
          component: () => import('./views/permohonan/Permohonan.vue'),
          meta: { 
            auth: true 
          }
        },
        {
          path: '/permohonan-data',
          name: 'permohonanData',
          component: () => import('./views/permohonan/permohonanData.vue'),
          meta: { 
            auth: true 
          }
        },
      //

      // USER
        {
          path: '/user/setting',
          name: 'user-setting',
          component: () => import('@/views/user/setting/UserSetting.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Setting', active: true }
            ],
            pageTitle: 'Setting',
            auth: true
          }
        },
      //

      ],
    },

    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/tologin',
          name: 'tologin',
          component: () => import('@/views/pages/ToLogin.vue'),
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/Register.vue'),
        },
        {
          path: '/aktivasi',
          name: 'aktivasi',
          component: () => import('@/views/pages/Aktivasi.vue'),
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            auth: true,
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },

    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if(Object.keys(store.getters['auth/user']).length == 0){
      const page = to.name
      next({ name: 'tologin', params: { page } })
    }
    else{
      next()
    } 
  } else {
    next()
  }
})
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = "none";
    }
})

export default router
