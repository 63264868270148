import Vue from 'vue'
import Vuex from 'vuex'
import alert from './stores/alert'
import auth from './stores/auth'
import akses from './stores/akses'
import dialog from './stores/dialog'
import VuexPersist from 'vuex-persist'
import axios from '@/axios.js'

const vuexPersist = new VuexPersist({
  key: 'my-app',
  storage: localStorage
})

Vue.use(Vuex)

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

Vue.use(Vuex)

export default new Vuex.Store({

  plugins: [vuexPersist.plugin],

  state: {
    prevUrl                 : '',
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    is_touch_device         : is_touch_device(),
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "dark",
    themePrimaryColor       : colors.primary,
    windowWidth             : null,
    navbarHidden            : false,
    //mobileMode            : themeConfig.mobile
    // TASK
    layanan_group           : [],
    task_user               : [],
    jadwal_user             : [],
    kedatangan              : []
  },

  mutations: {
    setPrevUrl: (state, value) => {
      state.prevUrl = value
    },
    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
      state.isVerticalNavMenuActive = value
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
      state.reduceButton = val
    },
    UPDATE_MAIN_LAYOUT_TYPE(state, val) {
      state.mainLayoutType = val
    },
    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
      state.verticalNavMenuItemsMin = val
    },
    UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
      state.verticalNavMenuWidth = width
    },


    // VxAutoSuggest

    UPDATE_STARRED_PAGE(state, payload) {

      // find item index in search list state
      const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

      // update the main list
      state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

      // if val is true add it to starred else remove
      if (payload.val) {
        state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
      }
      else {
        // find item index from starred pages
        const index = state.starredPages.findIndex((item) => item.url == payload.url)

        // remove item using index
        state.starredPages.splice(index, 1)
      }
    },

    // Navbar-Vertical

    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
      const starredPagesMore = state.starredPages.slice(10)
      state.starredPages     = list.concat(starredPagesMore)
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
      let downToUp                 = false
      let lastItemInStarredLimited = state.starredPages[10]
      const starredPagesLimited    = state.starredPages.slice(0, 10)
      state.starredPages           = starredPagesLimited.concat(list)

      state.starredPages.slice(0, 10).map((i) => {
        if (list.indexOf(i) > -1) downToUp = true
      })

      if (!downToUp) {
        state.starredPages.splice(10, 0, lastItemInStarredLimited)
      }
    },

    navbarUpdateStatus (state, val) {
      state.navbarHidden = val
    },


    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay       = val   },
    UPDATE_PRIMARY_COLOR(state, val)   { state.themePrimaryColor = val   },
    UPDATE_THEME(state, val)           { state.theme             = val   },
    UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },
    UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY           = val   },

    // TASK

    TASK_SET_USER (state, tasks) { 
      state.task_user = tasks
    },

    LAYANAN_GROUP_SETS (state, task) {
      state.layanan_group = task
    },

    TASK_ADD_DESC (state, item) {
      state.task_user.unshift(item)
    },
    TASK_ADD_ASC (state, item) {
      state.task_user.push(item)
    },

    TASK_UPDATE (state, item) {
      const taskIndex = state.task_user.findIndex((p) => p.id === item.id)
      Object.assign(state.task_user[taskIndex], item)
    },

    // JADWAL

    JADWAL_SET_USER (state, tasks) { 
      state.jadwal_user = tasks
    },
    JADWAL_ADD (state, item) {
      state.jadwal_user.unshift(item)
    },
    JADWAL_UPDATE (state, item) {
      const taskIndex = state.jadwal_user.findIndex((p) => p.id === item.id)
      Object.assign(state.jadwal_user[taskIndex], item)
    },

    JADWAL_REMOVE (state, Id) {
      const Index = state.jadwal_user.findIndex((p) => p.id === Id)
      state.jadwal_user.splice(Index, 1)
    },

    // KEDATANGAN
    
    KEDATANGAN_ADD (state, item) {
      state.kedatangan.unshift(item)
    }

  },

  actions: {
    setPrevUrl: ({commit}, value) => {
      commit('setPrevUrl', value)
    },

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },

    // TASK

    layananGroup ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/task-list-relation-group/layanan_group/layanan/asc`)
          .then((response) => {
            commit('LAYANAN_GROUP_SETS', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

    taskUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/task-list-user`, payload)
          .then((response) => {
            commit('TASK_SET_USER', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

    taskUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-update', data)
        .then((response) => {
          commit('TASK_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },
    
    taskStore ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-add', data)
        .then((response) => {
          if (data.urutan === 'desc') {
            commit('TASK_ADD_DESC', response.data.data)
          } else {
            commit('TASK_ADD_ASC', response.data.data)
          }
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    // JADWAL

    jadwalUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/task-list-user`, payload)
          .then((response) => {
            commit('JADWAL_SET_USER', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

    jadwalAdd ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-add', data)
        .then((response) => {
          commit('JADWAL_ADD', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    jadwalUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-update', data)
        .then((response) => {
          commit('JADWAL_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    jadwalRemove ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/task-delete/${id}`)
        .then((response) => {
          commit('JADWAL_REMOVE', id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    // Kedatangan
    kedatanganAdd ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-add', data)
        .then((response) => {
          commit('KEDATANGAN_ADD', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    }


  },

  getters: {
    prevUrl: state => state.prevUrl,
    windowBreakPoint: state => {

      // This should be same as tailwind. So, it stays in sync with tailwind utility classes
      if (state.windowWidth >= 1200) return "xl"
      else if (state.windowWidth >= 992) return "lg"
      else if (state.windowWidth >= 768) return "md"
      else if (state.windowWidth >= 576) return "sm"
      else return "xs"
    },

    scrollbarTag: state => {
      return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
    }
  },

  modules: {
    alert,
    auth,
    akses,
    dialog
  }
})
